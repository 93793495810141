<template>
	<div class="miniapp">
		<a-row :gutter="20">
			<a-col :span="18">
				<div class="miniapp-row1">
					<div class="miniapp-row1-top">
						<div class="rt-title">店铺统计 <span>更新时间:{{info.end_time}}</span></div>
						<div class="rt-cont">
							<div class="rt-cont-left">
								<div class="rt-cont-left-item">
									<span>支付订单数</span>
									<div>{{info.allOrderNum}} 
										<img v-if="info.allYesterdayOrderNum < info.allOrderNum" src="../../assets/img/diy/up.png" alt="">
										<img v-else src="../../assets/img/diy/down.png" alt="">
									</div>
									<span>昨日：{{info.allYesterdayOrderNum}}</span>
								</div>
								<div class="rt-cont-left-item">
									<span>支付金额（元）</span>
									<div>{{info.daypayPrice}}
										<img v-if="info.daypayPrice > info.yesterdayPrice" src="@/assets/img/diy/up.png" alt="">
										<img v-else src="@/assets/img/diy/down.png" alt="">
									</div>
									<span>昨日：{{info.yesterdayPrice}}</span>
								</div>
								<div class="rt-cont-left-item">
									<span>浏览量</span>
									<div>{{info.dayPageView}} 
										<img v-if="info.dayPageView > info.yesterdayPageView" src="@/assets/img/diy/up.png" alt="">
										<img v-else src="@/assets/img/diy/down.png" alt="">
									</div>
									<span>昨日：{{info.yesterdayPageView}}</span>
								</div>
								<div class="rt-cont-left-item">
									<span>会员数</span>
									<div>{{info.allAccoutNum}}</div>
								</div>
							</div>
							<div class="rt-cont-right">
								<a-button-group>
								    <a-button :type="current == 1 ?'primary':'default'" @click="current=1">土地订单</a-button>
								    <a-button :type="current == 2 ?'primary':'default'" @click="current=2">认养订单</a-button>
								    <a-button :type="current == 3 ?'primary':'default'" @click="current=3">商城订单</a-button>
								</a-button-group>
								<div v-show="current==1" class="chartcycle" id="chartLand"> </div>
								<div v-show="current==2" class="chartcycle" id="chartAdopt"> </div>
								<div v-show="current==3" class="chartcycle" id="chartOrder"> </div>
							</div>
						</div>
					
					</div>
					<div class="miniapp-row1-btm">
						<div class="rt-title">快速入口 <span>双击卡片可选择删除</span></div>
						<div class="rb-cont">
							<div class="rb-cont-item" @dblclick="delEnter(index)" v-for="(item,index) in enterInfo" :key="index" @click="toDetail(item.path)">
								<div class="rnci-icon" :style="enterbg(item.color)">
									<div class="rnci-icon-solid" :style="{background:item.color}"><i :class="item.icon" class="ri-xs"></i></div>
								</div>
								<span>{{item.name}}</span>
							</div>
							<div class="rb-cont-item" style="justify-content: center;" @click="addEnters">
								<i class="ri-add-line ri-lg"></i>
							</div>
							<div style="width: 13%;height: 1px;" v-for="val in 6" :key="'_'+val"></div>
						</div>
					</div>
				</div>
			</a-col>
			<a-col :span="6">
				<div class="miniapp-row2">
					<div class="rt-title">消息通知</div>
					<div class="miniapp-row3-item" v-for="(item,index) in message.list" :key="index" @click="toMessage(item)">
						<div class="miniapp-row3-item-title" style="width: 80%;">
							<span>{{formatTime(item.create_time,2)}}</span>
							{{item.title}}
						</div>
						<div class="miniapp-row3-item-read" v-if="item.is_see ==1">已读</div>
						<div class="miniapp-row3-item-read" v-else style="color: red;">未读</div>
					</div>
					<div class="pager">
						<a-pagination 
							size="small"
							hideOnSinglePage 
							:default-current="1" 
							:total="message.count" 
							@change="(e)=>getNewMsg(e)"/>
					</div>
					
				</div>
				<div class="miniapp-row3">
					<div class="rt-title">H5预览</div>
					<div class="miniapp-row3-h5qrcode">
						<img v-if="entrance && entrance.app_qr_code" :src="entrance.app_qr_code" style="width:160px;height:160px;" alt="">
						<span v-else>您还未配置H5域名~</span>
					</div>
					<!-- <div class="miniapp-row3-item" v-for="(item,index) in version.log" :key="index">
						<div class="miniapp-row3-item-title">
							{{item.version_desc}}
						</div>
						<span>{{formatTime(item.create_time,1)}}</span>
					</div>
					<div class="pager">
						<a-pagination 
							size="small"
							hideOnSinglePage 
							:default-page-size="6" 
							:default-current="1" 
							:total="version.total" 
							@change="(e)=>getHistoryLog(e)"/>
					</div> -->
				</div>
			</a-col>
		</a-row>
		
		<a-row :gutter="20">
			<a-col :span="18">
				<div class="miniapp-row4">
					<div id="chartColumn" style="width: 100%; height: 300px;"> </div>
				</div>
			</a-col>
			<a-col :span="6">
				<div class="miniapp-row5">
					<div class="rt-title">商品销量排行榜</div>
					<div class="zyl-goods-total">
						<div class="zyl-goods-total_rank">排名</div>
						<div class="zyl-goods-total_goods_over" style="margin-top: 0;">
							封面
						</div>
						<div class="zyl-goods-total_goods_name" style="color: #000000;">商品名称</div>
						<div class="zyl-goods-total_sale">销量</div>
					</div>
					<template  v-for="(item,index) in info.goods" >
						<div class="zyl-goods-total" :key="index" v-if="index<6">
							<div class="zyl-goods-total_rank" :class="['zyl-goods-total_red'+index]">{{index+1}}</div>
							<div class="zyl-goods-total_goods_over">
								<img style="width: 20px; height: 20px" :src="item.cover">
							</div>
							<div class="zyl-goods-total_goods_name">{{item.goods_name}}</div>
							<div class="zyl-goods-total_sale">{{item.true_sale}}</div>
						</div>
					</template>
				</div>
			</a-col>
		</a-row>
	
		<a-modal title="快捷入口选择" :visible="show.enter" width="1000px"
				@ok="addShortcutEnter"
				@cancel="show.enter = false">
			<div class="rb-cont">
				<div class="rb-cont-item" :class="show.enter_index == index ? 'rb-cont-active':''" @click="show.enter_index = index" v-for="(item,index) in enter" :key="index">
					<div class="rnci-icon" :style="enterbg(item.color)">
						<div class="rnci-icon-solid" :style="{background:item.color}"><i :class="item.icon" class="ri-xs"></i></div>
					</div>
					<span>{{item.name}}</span>
				</div>
				<div style="width: 13%;height: 1px;" v-for="val in 6" :key="'_'+val"></div>
			</div>
		</a-modal>
	</div>
</template>

<script>
import * as echarts from 'echarts'
import walden from '@/assets/js/walden.js'
import { computed, reactive, toRefs } from 'vue'
import store from '@/store'
import router from '@/router'
import { $post } from '@/api/http'
import setModel from '@/api/set'
import commonModel from '@/api/common'
import tool from '@/util/tool'
import RouteHelper from '@/router/routeHelper.js'
import programModel from '@/api/saas/app'
const rhModel = new RouteHelper()
export default{
	setup(){
		const state = reactive({
			chartOrder:null,
			chartLand:null,
			chartAdopt:null,
			info:{},
			current:1,	//当前选中的数据类型 1=土地 2=认养 3=商城
			version:{	//更新日志
				log:[],
				page:1,
				total:0
			},
			message:{	//消息通知
				list:[],
				page:1,
				count:0
			},
			enter:[],
			show:{
				enter:false,
				enter_index:'',
			},
			entrance:null,	//h5/pc信息
			
			enterInfo:[
				{name:'土地',color:'#29C76E',icon:'ri-layout-masonry-fill',path:'/land/landList'},
				{name:'认养',color:'#9483FF',icon:'ri-mickey-fill',path:"/adopt/list"},
				{name:'商品',color:'#16C0F9',icon:'ri-shopping-bag-3-fill',path:"/goods/list"},
				{name:'会员',color:'#FFBA00',icon:'ri-user-fill',path:"/account/accountList"},
				{name:'财务',color:'#FF6EB4',icon:'ri-money-cny-circle-fill',path:"/fund/overview"},
				{name:'监控',color:'#FF8E58',icon:'ri-webcam-fill',path:"/live/liveList"},
				{name:'优惠券',color:'#F883F8',icon:'ri-coupon-fill',path:"/marketing/couponList"},
				{name:'提现',color:'#FE7070',icon:'ri-wallet-3-line',path:"/account/withdrawList"},
				{name:'商城订单',color:'#75DCFF',icon:'ri-file-list-3-line',path:"/order/orderList"},
				{name:'土地订单',color:'#1BDC6F',icon:'ri-file-list-3-line',path:"/land/land_order"},
				{name:'认养订单',color:'#B7ACFF',icon:'ri-file-list-3-line',path:"/adopt/orderList"},
			],
			timer:null
		})

		;(function initLoad(){
			let nav = { first: "/miniapp", second: "", third: '' }
			store.dispatch('setMiniNav', nav);
			localStorage.setItem("miniapp_nav_key",JSON.stringify(nav))

			$post("shopPayNum").then(res=>{
				state.info = res.data
				drawLine()
			})
			getNewMsg(1)
			getHistoryLog(1)

			setModel.getFarmSet(['miniapp_fast_enter']).then(res=>{
				if( res.miniapp_fast_enter ) state.enterInfo = JSON.parse(res.miniapp_fast_enter)
			})

			//h5/pc预览信息
			programModel.getProgramUrl(res=>state.entrance = res)
		})()

		const enterbg = computed(()=>{
			return function(color){
				return `background:rgba(${colorRgb(color)},0.3)`
			}
		})

		function getHistoryLog(page){
			commonModel.getHistoryLog(page,6,res=>{
				state.version.log =res.list 
				state.version.page =res.page 
				state.version.total =res.count 
			})
		}

		function getNewMsg(page){
			commonModel.getMessage(page,5,res=>state.message = res)
		}

		//双击删除
		function delEnter(index){
			let timers = state.timer
			if( timers ){
				clearTimeout(timers)
				state.timer = null
			}
			tool.confirm("确认删除该快捷按钮吗？").then(()=>{
				state.enterInfo.splice(index,1)
				setModel.setFarmSet({miniapp_fast_enter:JSON.stringify(state.enterInfo)})
			}).catch(()=>{})
		}

		//单击跳转
		function toDetail(path){
			let timers = state.timer
			if( timers ){
				clearTimeout(timers)
				state.timer = null
			}
			state.timer = setTimeout(function(){
				let nav = rhModel.getPathParent(store.state.permPlatform,path)
				store.dispatch('setMiniNav',nav)	
				router.push(path)
			},300)
		}

		function drawLine() {
			state.chartColumn = echarts.init(document.getElementById('chartColumn'),'walden');
			state.chartColumn.setOption({
					title : {
							text: '最近七日',
							subtext: '最近七日'
						},
						tooltip : {
							trigger: 'axis'
						},
						legend: {
							data:['商城','土地','认养']
						},
						toolbox: {
							show : true,
							feature : {
								mark : {show: true},
								dataView : {show: true, readOnly: false},
								magicType : {show: true, type: ['line', 'bar']},
								restore : {show: true},
								saveAsImage : {show: true}
							}
						},
						calculable : true,
						xAxis : [
							{
								type : 'category',
								boundaryGap : false,
								data : state.info.Turnover.timeArray
							}
						],
						yAxis : [
							{
								type : 'value',
								axisLabel : {
									formatter: '{value} 元'
								}
							}
						],
						series : [
							{
								name:'商城',
								type:'line',
								data: state.info.Turnover.shopdata.priceByDay,
								smooth:true,
								markPoint : {
									data : [
										{type : 'max', name: '最大值'},
										{type : 'min', name: '最小值'}
									]
								},
								markLine : {
									data : [
										{type : 'average', name: '平均值'}
									]
								}
							},
							{
								name:'土地',
								type:'line',
								smooth:true,
								data:state.info.Turnover.landdata.priceByDay,
								markPoint : {
									data : [
										{name : '周最低', value : -2, xAxis: 1, yAxis: -1.5}
									]
								},
								markLine : {
									data : [
										{type : 'average', name : '平均值'}
									]
								}
							},{
								name:'认养',
								type:'line',
								smooth:true,
								data:state.info.Turnover.adoptdata.priceByDay,
								markPoint : {
									data : [
										{name : '周最低', value : -2, xAxis: 1, yAxis: -1.5}
									]
								},
								markLine : {
									data : [
										{type : 'average', name : '平均值'}
									]
								}
							}
						]
			});
			
			
			state.chartOrder =  echarts.init(document.getElementById('chartOrder'),'walden');
			state.chartOrder.setOption({
				title : {
					text: '订单数据统计',
					subtext: '',
					x:'center'
				},
				tooltip : {
					trigger: 'item',
					formatter: "{a} <br/>{b} : {c} ({d}%)"
				},
				legend: {
					orient: 'vertical',
					left: 'left',
					data: ['待配送','配送中','已完成','退款中','已退款','售后订单']
				},
				series : [
					{
						name: '访问来源',
						type: 'pie',
						radius : '55%',
						center: ['50%', '60%'],
						data:[
							{value:state.info.shopSta.ToBeDelivered, name:'待配送'},
							{value:state.info.shopSta.InDelivery, name:'配送中'},
							{value:state.info.shopSta.completed, name:'已完成'},
							{value:state.info.shopSta.Refunding, name:'退款中'},
							{value:state.info.shopSta.refunded, name:'已退款'},
							{value:state.info.shopSta.sale, name:'售后订单'}
						],
						itemStyle: {
							emphasis: {
								shadowBlur: 10,
								shadowOffsetX: 0,
								shadowColor: 'rgba(0, 0, 0, 0.5)'
							}
						}
					}
				]
			})
			
			state.chartLand =  echarts.init(document.getElementById('chartLand'),'walden');
			state.chartLand.setOption({
				title : {
						text: '土地数据统计',
						subtext: '',
						x:'center'
					},
					tooltip : {
						trigger: 'item',
						formatter: "{a} <br/>{b} : {c} ({d}%)"
					},
					legend: {
						orient: 'vertical',
						left: 'left',
						data: ['待播种','种植中','已成熟','未出租','已出租']
					},
					series : [
						{
							name: '访问来源',
							type: 'pie',
							radius : '55%',
							center: ['50%', '60%'],
							data:[
								{value:state.info.landSpecSta.toBeSeeded, name:'待播种'},
								{value:state.info.landSpecSta.planting, name:'种植中'},
								{value:state.info.landSpecSta.mature, name:'已成熟'},
								{value:state.info.landSpecSta.notRented, name:'未出租'},
								{value:state.info.landSpecSta.rentedOut, name:'已出租'},
							],
							itemStyle: {
								emphasis: {
									shadowBlur: 10,
									shadowOffsetX: 0,
									shadowColor: 'rgba(0, 0, 0, 0.5)'
								}
							}
						}
					]
			})
			
			state.chartAdopt=  echarts.init(document.getElementById('chartAdopt'),'walden');
			state.chartAdopt.setOption({
					title : {
						text: '认养数据统计',
						subtext: '',
						x:'center'
					},
					tooltip : {
						trigger: 'item',
						formatter: "{a} <br/>{b} : {c} ({d}%)"
					},
					legend: {
						orient: 'vertical',
						left: 'left',
						data: ['待分配','认养中','已成熟']
					},
					series : [
						{
							name: '访问来源',
							type: 'pie',
							radius : '55%',
							center: ['50%', '60%'],
							data:[
								{value:state.info.adoptSta.ToBeAssigned, name:'待分配'},
								{value:state.info.adoptSta.Adopting, name:'认养中'},
								{value:state.info.adoptSta.Mature, name:'已成熟'},
							],
							itemStyle: {
								emphasis: {
									shadowBlur: 10,
									shadowOffsetX: 0,
									shadowColor: 'rgba(0, 0, 0, 0.5)'
								}
							}
						}
					]
				
			})
		}

		function colorRgb(color){
			// 16进制颜色值的正则
			var reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/;
			// 把颜色值变成小写
			color = color.toLowerCase();
			if (reg.test(color)) {
				// 如果只有三位的值，需变成六位，如：#fff => #ffffff
				if (color.length === 4) {
					var colorNew = "#";
					for (var i = 1; i < 4; i += 1) {
						colorNew += color.slice(i, i + 1).concat(color.slice(i, i + 1));
					}
					color = colorNew;
				}
				// 处理六位的颜色值，转为RGB
				var colorChange = [];
				for (var i = 1; i < 7; i += 2) {
					colorChange.push(parseInt("0x" + color.slice(i, i + 2)));
				}
				return colorChange.join(",");
			}
			return color;
		}

		function addEnters(){
			let enter = [
				{name:'土地',color:'#29C76E',icon:'ri-layout-masonry-fill',path:'/land/landList'},
				{name:'认养',color:'#9483FF',icon:'ri-mickey-fill',path:"/adopt/list"},
				{name:'商品',color:'#16C0F9',icon:'ri-shopping-bag-3-fill',path:"/goods/list"},
				{name:'会员',color:'#FFBA00',icon:'ri-user-fill',path:"/account/accountList"},
				{name:'财务',color:'#FF6EB4',icon:'ri-money-cny-circle-fill',path:"/fund/overview"},
				{name:'监控',color:'#FF8E58',icon:'ri-webcam-fill',path:"/live/liveList"},
				{name:'优惠券',color:'#F883F8',icon:'ri-coupon-fill',path:"/marketing/couponList"},
				{name:'提现',color:'#FE7070',icon:'ri-wallet-3-line',path:"/account/withdrawList"},
				{name:'商城订单',color:'#75DCFF',icon:'ri-file-list-3-line',path:"/order/orderList"},
				{name:'土地订单',color:'#1BDC6F',icon:'ri-file-list-3-line',path:"/land/land_order"},
				{name:'认养订单',color:'#B7ACFF',icon:'ri-file-list-3-line',path:"/adopt/orderList"},
			]
			let permModule = store.state.modulesPerm
			if( permModule.event == 1 )	{
				enter.push({name:'活动',color:'#D4AE82',icon:'ri-award-fill',path:"/adhibition/active/list"})
			}
			if( permModule.sales_share == 1 ) {
				enter.push({name:'分销',color:'#FFA095',icon:'ri-share-fill',path:"/adhibition/distribution/list"})
			}
			if( permModule.salesroom == 1 ) {
				enter.push({name:'门店',color:'#FE7070',icon:'ri-store-2-fill',path:"/adhibition/outlet/list"})
			}
			if( permModule.shop_card == 1)	{
				enter.push({name:'礼品卡',color:'#F9CA88',icon:'ri-bank-card-fill',path:"/adhibition/card/list"})
			}
			if( permModule.pointsMall == 1 ) {
				enter.push({name:'积分商城',color:'#30CECC',icon:'ri-money-dollar-circle-fill',path:"/adhibition/integral/type"})
			}
			if( permModule.live_broadcast == 1 ) {
				enter.push({name:'直播',color:'#EE1289',icon:'ri-live-fill',path:"/adhibition/streaming/index"})
			}
			if( permModule.return_to_cash == 1 ) {
				enter.push({name:'返现活动',color:'#12E0BC',icon:'ri-copper-diamond-fill',path:"/marketing/cashback/list"})
			}
			if( permModule.multi_merchant == 1 ){
				enter.push({name:'多商户',color:'#FDBF75',icon:'ri-store-3-fill',path:"/adhibition/store/set"})
			}
			if( permModule.marketing_9_lock_draw = 1 ){
				enter.push({name:'抽奖',color:'#FFA095',icon:'ri-medal-line',path:"/marketing/lottery/set"})
			}
			
			this.enter = enter
			this.show.enter = true
		}
		//添加快捷入口
		function addShortcutEnter(){
			let { enter_index } = state.show , flag = false
			state.enterInfo.forEach((item,index)=>{
				if( item.path == state.enter[enter_index].path ){
					flag = true
				}
			})
			if( flag ){
				tool.message('快捷入口已存在！',"warning")
				return
			}
			if( state.enterInfo.length < 13 ){
				state.enterInfo.push(state.enter[enter_index])
				setModel.setFarmSet({miniapp_fast_enter:JSON.stringify(state.enterInfo)})
				state.show.enter = false
			}else{
				tool.message('快捷入口不能操作13个哦！请删除其他入口后再添加！',"warning")
			}
		}

		function toMessage(row){
			let  path = '' ,query=null
			if( row.is_see == 0 ){
				commonModel.hanldeMessage('isread',{ids:[row.id]},()=>{})
			}
			if( row.type == 1 ){
				path = '/order/detail'
				query = {id:row.to_id}
			}
			if( row.type  ==7 ){
				path='/land/old_order_detail'
				query = {order_id:row.to_id}
			}
			if( row.type  ==8 ){
				path="/adopt/orderDetail"
				query = {order_id:row.to_id}
			}
			if( row.type  ==6 ){
				path="/adhibition/store/withdraw"
			}
			if( row.type  ==5 ){
				path="/account/withdrawList"
			}
			if( row.type  ==2 ){
				path="/adhibition/store/enter_audit"
			}
			if( row.type  ==3 ){
				path="/adhibition/distribution/list"
			}
			if( row.type  ==4 ){
				path="/order/detail"
				query = {id:row.to_id}
			}
			if( row.type  ==19 ){
				path="/land/orderDetail"
				query = {order_id:row.to_id}
			}
			
			let nav = rhModel.getPathParent(store.state.permPlatform,path)
			store.dispatch('setMiniNav',nav)	
			if( query ){
				router.push({path,query:query})
			}else{
				router.push(path)
			}
		}

		/**
		 * 时间格式化
		 * @param {Object} time 时间 2020-05-20 14:00:00
		 * @param {Object} type  type = 1 : 2020-05-20 
		 * 						 type = 2 : 05-20 14:00 
		 */
		function formatTime(time ,type){
			let arr = time.split(" ")
			if( type == 1 ) {
				return arr[0]
			}
			if( type == 2 ){
				let str1 = arr[0].split("-") , str2 = arr[1].split(":")
				return str1[1]+'-'+str1[2] +' '+str2[0]+':'+str2[1]
			}
		}
		return{
			...toRefs(state),
			enterbg,
			getNewMsg,
			delEnter,
			toDetail,
			addEnters,
			addShortcutEnter,
			toMessage,
			formatTime,
			getHistoryLog
		}
	}
}
</script>

<style lang="scss">
	.rt-title{
		font-size: 18px;
		font-weight: bold;
		
		span{
			font-size: 14px;
			font-weight: 300;
			color: #999;
			margin-left: 12px;
		}
	}
	
	.miniapp-row1{
		background: #fff;
		width: 100%;
		height: 500px;
		
		
		
		&-top{
			width: 100%;
			height: 300px;
			border-bottom: 1px solid #f4f4f4;
			padding: 24px;
			box-sizing: border-box;
		}
		
		.rt-cont{
			display: flex;
			
			&-left{
				width: 50%;
				display: flex;
				flex-wrap: wrap;
				border-right: 1px solid #dedede;
				margin-top: 12px;
				
				&-item{
					width: 50%;
					margin-bottom: 36px;
					
					div{
						font-size: 24px;
						font-weight: bold;
						
						i{
							position: relative;
							top: 3px;
						}
						img{
							margin-left: 10px;
						}
					}
					
					span{
						color: #999;
						font-size: 13px;
					}
				}
			}
			
			&-right{
				margin-top: 12px;
				padding: 0 48px;
				width: 50%;
				position: relative;
				
				.rtr-tip{
					font-size: 12px;
					color: #999;
					line-height: 24px;
				}
				
				.chartcycle{
					margin-top: 24px;
					width: 400px; 
					height: 180px;
				}
			}
		}
	
		&-btm{
			padding:12px 24px;
		}
	
	}
	.miniapp-row2,
	.miniapp-row3{
		background: #fff;
		width: 100%;
		height: 240px;
		padding: 24px;
		box-sizing: border-box;
	}
	.miniapp-row3{
		margin-top: 20px;
		
		&-item{
			width: 100%;
			display: flex;
			font-size: 12px;
			justify-content: space-between;
			line-height: 24px;
			cursor: pointer;
			span{
				color: #999;
				margin-right: 12px;
			}
			&-title{
				width: 60%;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}
			&-read{
				color: #999;
			}
		}

		&-h5qrcode{
			text-align: center;	

			span{
				position: relative;
				color: #868686;
				top: 64px;
			}
		}

		
	}
	.miniapp-row4{
		width: 100%;
		background: #fff;
		height: 300px;
		margin-top: 12px;
		padding: 24px;
		box-sizing: border-box;
	}
	.miniapp-row5{
		width: 100%;
		background: #fff;
		height: 300px;
		margin-top: 12px;
		padding: 24px;
		box-sizing: border-box;
		
		// 商品销量统计
		.zyl-goods-total{
			width: 100%;
			height: 35px;
			line-height: 35px;
			display: flex;
			align-items: center;
			border-bottom: 1px solid #F4F4F4;
			font-size: 12px;
			&_rank{
				width: 35px;
				text-align: center;
			}
			&_goods_over{
				width: 35px;
				height: 35px;
				margin-right: 10px;
				margin-top:10px;
			}
			
			&_goods_name{
				width: 70%;
				height: 35px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				font-size: 12px;
				color: #868686;
			}
			
			&_sale{
				text-align: center;
				width: 20%;
			}
			
			&_red0{
				color: red;
				font-size: 18px;
				font-weight: bold;
			}
			
			&_red1{
				color: #ff5a18;
				font-size: 16px;
				font-weight: bold;
			}
			
			&_red2{
				color: #ff6100;
				font-size: 14px;
				font-weight: bold;
			}
		}
	}
	
	.rb-cont{
		display: flex;
		flex-wrap: wrap;
		margin-top: 12px;
		&-active{
			background: #F6F6F6;
		}
	}
	.rb-cont-item{
		box-sizing: border-box;
		width: 14%;
		border: 1px solid #f4f4f4;
		display: flex;
		align-items: center;
		padding: 12px 16px;
		cursor: pointer;
		margin-left: -1px;
		margin-top: -1px;
		>span{
			font-size: 12px;
		}
		.rnci-icon{
			width:36px;
			height: 36px;
			border-radius: 50%;
			background: rgba($color: #ff557f, $alpha: .2);
			text-align: center;
			line-height: 48px;
			margin-right: 6px;
			&-solid{
				width: 24px;
				height: 24px;
				border-radius: 50%;
				background: #ff557f;
				margin: 6px;
				color: #fff;
				i{
					position: relative;
					top: -12px;
				}
			}
		}
	}
</style>
